import React from "react";

const Home = () => {
  return (
    <>
      <div style={{ marginTop: "50px" }} className="container-fluid">
        <p className="firstHead">Prince George Lodge No. 115 AF &amp; AM</p>
        <p className="secondHead">Stated Communication: 1st Thursday</p>
        <p className="secondHead">Meeting at 7:30 p.m.; Dinner at 6:30 p.m.</p>
        <img
          className="img-fluid"
          src="/images/lodge.jpg"
          alt="Lodge Building"
        />
        <div className="row">
          <div className="col-md-5 col-xs-8">
            <div>Prince George Lodge No. 115</div>
            <div>5709 Ruffin Road</div>
            <div>Prince George, VA 23875</div>
          </div>
          <div className="col-md-4 col-xs-4">
            <img
              className="img-fluid"
              style={{ marginTop: "10px" }}
              src="/images/150pin.jpg"
              alt="Prince George Lodge No. 115"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <h2>Next Stated</h2>
            <p>
              The April 3rd stated meeting will begin at 7:30 p.m. Dinner will
              start at 6:30.
            </p>
            <p>
              The menu for dinner: Rotisserie Chicken, Mashed Potatoes and Green
              Beans, and a surprise dessert
            </p>
          </div>

          <div className="col-md-6">
            <h2>Upcoming Events</h2>
            <ul>
              <li>Tuesday, April 22 at 7 pm - District Ritual School</li>

              <li>
                Wednesday, April 23 at 6 pm - Solomon's Circle at Prince George
                Lodge. The next few sessions of Solomon's Circle will be
                discussing a book titled "Freemasonry's Hidden Brain Science" by
                by Michael Schiavello. You can find a copy at Macoy's Publishing
                or on Amazon. ISBN-10 0853185654
              </li>
            </ul>
          </div>

          <div className="col-md-6">
            <h2>Fundraisers</h2>
            <ul>
              <li>Friday, April 18 (Good Friday), Fish Fry at the Lodge</li>
              <li>
                Wednesday, May 14, Harold Jackson Bibey, Sr. Memorial Golf
                Tournament
              </li>
              <li>Saturday, July 19, Barbecue at the Lodge</li>
              <li>Saturday, Nov. 1, Brunswick Stew</li>
            </ul>
          </div>
          <div className="col-md-6">
            <h2>Harold Jackson Bibey, Sr. Memorial Golf Tournament</h2>
            <p>
              Join the friends of Prince George Masonic Lodge No. 115 for the
              2025 Harold Jackson Bibey Sr. Memorial Golf Tournament presented
              by: Burnett & Williams
            </p>

            <p>
              This event honors the memory of Brother Jack Bibey Sr. and his
              love of golf. All proceeds benefit four annual $1,000 awards
              through the Richard S. Zuskin Memorial Scholarship Fund as well as
              local book fairs and other education related services.
            </p>

            <p>
              Teams of 4 are invited to play for $125 per player or $500 per
              team. Hole Sponsorships are available and other sponsorship
              opportunities are available for $1000 which include a free 4
              person entry.
            </p>

            <p>Call 757-262-7096 to reserve your spot or to donate.</p>

            <p>
              Checks may be made payable to Prince George Lodge 115 and mailed
              to Prince George Lodge C/O Joe Hudson, PO Box 412, Sutherland, VA
              23885
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
