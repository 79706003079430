import React from "react";

const East = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <p>From The East</p>
          <i>“The Pillars of Tomorrow”</i>
        </div>
        <div className="card-body">
          <p>Brethren,</p>
          <p>
            As April unfolds, so too does the beauty and energy of spring. The
            flowers bloom, the days grow longer, and the world seems to take a
            deep breath of fresh air. It's the season of rebirth and renewal—and
            a perfect time to reflect on our theme for the month: The Power of
            Positive Thinking.
          </p>

          <p>
            With Easter on the horizon, we are reminded of the hope and
            transformation that lie at the heart of this season. It's a time
            when the world comes back to life—and our Lodge is no different.
            There's a spirit of movement and purpose rising within Prince George
            Lodge, and I truly believe we are on the brink of something special.
          </p>

          <p>
            This month, we are excited to host our First Annual Good Friday Fish
            Fry on April 18th! We'll begin preparing food early that morning and
            start serving the public at 11:00 a.m. This is a brand-new event for
            our Lodge—and a powerful opportunity to welcome our community, build
            visibility, and raise funds for future efforts. We're asking for
            your help not only with participation, but also with promotion—if
            you've got creative ideas for spreading the word, we want to hear
            them!
          </p>

          <p>
            We'll also be getting our hands dirty (in the best way) during our
            Spring Lodge Cleanup. This project will help our building and
            grounds reflect the pride we have in our Lodge, and the more
            Brothers we have involved, the better. We will be doing this
            throughout the upcoming week! There's no better way to reconnect
            with one another than through shared labor and laughter.
          </p>

          <p>
            Looking ahead, mark your calendars for Wednesday, May 14th, when we
            host the Harold Jackson Bibey Sr. Memorial Golf Tournament at
            Dogwood Trace Golf Course, located at 3108 Homestead Dr, Petersburg,
            VA. The event runs from 7:30 a.m. to 4:00 p.m., and sponsorship
            opportunities are still available. This tournament is a great chance
            to honor the memory of a cherished Brother, while also supporting
            the future of our Lodge.
          </p>

          <p>
            I also want to highlight our Solomon's Circle—a new, informal
            gathering designed to bring Brethren together to explore the
            philosophical side of Freemasonry. These thought-provoking
            discussions give us a chance to go deeper than ritual or tradition,
            and to reflect on the ideas that make Masonry timeless. While we
            originally held this circle on the 2nd and 4th Wednesdays of each
            month, we've adjusted the schedule to just the 4th Wednesday, to
            better fit the demands of the spring and summer seasons. So far,
            participation has been strongest on that day, and we're excited to
            continue growing this powerful part of Lodge life.
          </p>

          <p>
            More social events are also on the horizon, and we're looking
            forward to using the warmer weather as an opportunity to bring
            Brothers together and welcome new faces into our fold.
          </p>

          <p>
            To the Brothers we haven't seen in a while: your Lodge is here, and
            we miss you. There is something meaningful happening at Prince
            George Lodge—come be part of it.
          </p>

          <p>
            Let us carry into this month the spirit of renewal, hope, and
            forward-thinking. The best days of our Lodge are not behind us—they
            are ahead. All it takes is action, optimism, and a few good men
            working side by side.
          </p>

          <p> “As a man thinketh in his heart, so is he.” – Proverbs 23:7</p>

          <h2>Solomon's Circle:</h2>

          <p>
            The next few sessions of Solomon's Circle will be discussing a book
            titled "Freemasonry's Hidden Brain Science" by by Michael
            Schiavello. You can find a copy at Macoy's Publishing or on Amazon.
            ISBN-10 0853185654
          </p>
          <p>
            Description: This is Freemasonry in its ultimate expression, deeper
            and more vast than you could ever have imagined. Offering a
            revolutionary approach to the Craft, Freemasonry's Hidden Brain
            Science explains and proves the genius of Masonic ritual as a
            neurological, psychological, and metaphysical handbook for
            self-improvement and the attainment of higher consciousness.{" "}
          </p>

          <p>
            Best-selling author, television personality, and 32° Freemason,
            Michael Schiavello, reveals startling discoveries made while
            plumbing the depths of Masonic ritual and symbolism.
          </p>

          <p>
            Discover how King Solomon's Temple correlates specifically to the
            makeup and measurements of the human nervous system, and unlock the
            true meaning of the Masonic pillars, Hiram Abiff, the Three
            Ruffians, the Legend of the Third Degree, Euclid's 47th Proposition,
            the Tyler's sword, Caution, the Past Master Jewel, the obligation to
            not make women Freemasons, and more.
          </p>

          <p>
            Unfolding like a self-help treasure map, Freemasonry's Hidden Brain
            Science reveals hidden meanings found within Freemasonry's
            allegories and symbolism, which go far beyond their literal
            interpretations and will change the way you think, act, and feel.
          </p>

          <p>
            Foreword by Pearl Harbor survivor Edward Hall 33°. Paperback, 230
            pages.
          </p>

          <p>
            Come see what it's about - 2nd and 4th Wednesdays at Prince George
            Lodge No. 115. Open to all Master Masons.
          </p>
        </div>
        <div className="card-footer">
          <p>Donald H. Rackley Jr.</p>
          <p>Worshipful Master, PG Lodge #115</p>
        </div>
      </div>
    </>
  );
};

export default East;
